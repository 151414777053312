import React from 'react';
import NotificationForm from '../components/NotificationForm';
import { ADD } from '../../../services/constants';

const AddNotification = () => {
  return (
    <div>
      <h2>Notification Form</h2>
      <NotificationForm mode={ADD} />
    </div>
  );
};

export default AddNotification;
