const parsePhoneNumber = (phoneNumber) => {
  if (phoneNumber === null) {
    return null;
  }

  phoneNumber = phoneNumber.replace(/\D/g, "");
  if (phoneNumber.startsWith("251")) {
    phoneNumber = phoneNumber.substring(3);
    return phoneNumber;
  }
  if (phoneNumber.startsWith("09") && phoneNumber.length === 10) {
    phoneNumber = phoneNumber.substring(1);
    return phoneNumber;
  }
  if (phoneNumber.startsWith("07") && phoneNumber.length === 10) {
    phoneNumber = phoneNumber.substring(1);
    return phoneNumber;
  }
  if (phoneNumber.startsWith("9")) {
    return phoneNumber;
  }
  if (phoneNumber.startsWith("7")) {
    return phoneNumber;
  }
  if (phoneNumber.startsWith("+9")) {
    phoneNumber = phoneNumber.substring(1);
    return phoneNumber;
  } else {
    return null;
  }
};

export { parsePhoneNumber };
