import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationListCard from "../components/NotificationListCard";
import { useLocation } from "react-router-dom";
import { getLocationSpecificNotifications } from "../../../services/notification_api_services";
import { toast } from "react-toastify";

const NotificationsList = () => {
  let query = new URLSearchParams(useLocation().search);
  let bot_user_id = query.get("bot_user_id");
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getNotificationsList = async () => {
    try {
      var response = await getLocationSpecificNotifications({
        user_id: bot_user_id,
      });
      const isSuccessfulResponse =
        response.status === 201 || response.status === 200;
      const isSuccessfulNestedResponse =
        response.response?.status === 201 || response.response?.status === 200;

      if (isSuccessfulResponse || isSuccessfulNestedResponse) {
        console.log("NOTIFICATIONS LIST : ", response);
        if (response?.data?.results) {
          setNotificationList(response?.data?.results);
        } else if (response?.data) {
          setNotificationList(response?.data);
        }
        setIsLoading(false);
      } else {
        toast.error("Error getting notifications list, please try again.", {
          position: "top-center",
        });
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  useEffect(() => {
    getNotificationsList();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <h2>Notifications</h2>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {notificationList &&
            notificationList.map(
              (notification) =>
                notification.active && (
                  <NotificationListCard
                    key={notification.id}
                    label={notification.name}
                    id={notification.id}
                    active={notification.active}
                    createdAt={notification.created_at}
                  />
                )
            )}
        </>
      )}
    </Box>
  );
};

export default NotificationsList;
