import { Box, Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const Home = () => {
  console.log("home");
  return (
    <div>
      <div>Home</div>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: '10px' }}>
        <Box p='10px'>
          <NavLink to='/da_registration'>
            <Button variant='outlined'>DA Registration</Button>
          </NavLink>
        </Box>
        <Box p='10px'>
          <NavLink to='/da_update_profile'>
            <Button variant='outlined'>DA Update Profile</Button>
          </NavLink>
        </Box>
        <Box p='10px'>
          <NavLink to='/feedback'>
            <Button variant='outlined'>Feedback Form</Button>
          </NavLink>
        </Box>
        <Box p='10px'>
          <NavLink to='/farmer_registration'>
            <Button variant='outlined'>Farmer Registration</Button>
          </NavLink>
        </Box>
        <Box p='10px'>
          <NavLink to='/record_reach'>
            <Button variant='outlined'>Reach Form</Button>
          </NavLink>
        </Box>
        <Box p='10px'>
          <NavLink to='/notification/create'>
            <Button variant='outlined'>Create Notification</Button>
          </NavLink>
        </Box>
        <Box p='10px'>
          <NavLink to='/notification/list'>
            <Button variant='outlined'>Notification List</Button>
          </NavLink>
        </Box>
        <Box p='10px'>
          <NavLink to='/register_report_user'>
            <Button variant='outlined'>Report User Registration</Button>
          </NavLink>
        </Box>
        <Box p='10px'>
          <NavLink to='/add_development_group'>
            <Button variant='outlined'>Add Development Group</Button>
          </NavLink>
        </Box>
      </Box>
    </div>
  )
};

export default Home;
