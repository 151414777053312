import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "./utils/i18n.js";
// main.js or index.js
import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11"; // for IE 11 support
import "react-app-polyfill/stable";
import "intl-pluralrules";
import "whatwg-fetch"; // Import the fetch polyfill

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
