import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useLocation } from 'react-router-dom'
import { isEmpty } from '../utils/isEmpty'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { addDevelopmentGroup } from '../services/api_services'

const DevelopmentGroupForm = () => {
  const { t } = useTranslation()
  const tele = window.Telegram.WebApp
  let query = new URLSearchParams(useLocation().search)
  let da_id = query.get('da_id')
  let kebele_id = query.get('kebele_id')
  const [addDevGroupIsLoading, setAddDevGroupIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({})

  const developmentGroupTypeOptions = [
    {
      value: 'men_only',
      label: t('men_only'),
    },
    {
      value: 'women_only',
      label: t('women_only'),
    },
    {
      value: 'mixed',
      label: t('mixed'),
    },
  ]

  const validationSchema = yup.object({
    group_name: yup.string().required(t('required')),
    group_type: yup.string().required(t('required')),
  })

  const formik = useFormik({
    initialValues: {
      group_name: '',
      group_type: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setAddDevGroupIsLoading(true)

        const response = await addDevelopmentGroup({
          body: {
            kebele: kebele_id,
            created_by: da_id,
            group_name: values.group_name,
            group_type: values.group_type,
          },
          setAddDevGroupIsLoading: setAddDevGroupIsLoading,
        })

        console.log('response : ', response)
        if (!isEmpty(response.status) && response.status == 201) {
          await tele.sendData(
            JSON.stringify({ data: response.data, status_code: 201 })
          )
          setAddDevGroupIsLoading(false)

          return
        } else {
          if (response?.status == 400) {
            setFormErrors(response?.data)
          }

          if (response?.response?.status == 400) {
            setFormErrors(response?.response?.data)
          }

          toast.error(t('error_adding_development_group'), {
            position: 'top-center',
          })
          setAddDevGroupIsLoading(false)

          return
        }
      } catch (e) {
        toast.error(t('error_adding_development_group'), {
          position: 'top-center',
        })
      }
    },
  })

  useEffect(() => {
    tele.expand()
    tele.MainButton.text = t('add')
    tele.MainButton.show()
    tele.MainButton.onClick(formik.handleSubmit)
    tele.ready()
  }, [tele, kebele_id])

  useEffect(() => {
    if (!isEmpty(formErrors)) {
      for (const [key, value] of Object.entries(formErrors)) {
        if (Array.isArray(value)) {
          const errorString = value.join(', ')
          formik.setFieldError(key, errorString)
        } else {
          formik.setFieldError(key, value)
        }
      }
    }
  }, [formErrors])

  return (
    <div>
      <Typography
        variant='h5'
        py={2}>
        {t('add_development_group')}
      </Typography>
      {addDevGroupIsLoading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <TextField
            variant='outlined'
            fullWidth
            margin='dense'
            id='group_type'
            name='group_type'
            label={t('development_group_type')}
            value={formik.values.group_type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.group_type && Boolean(formik.errors.group_type)
            }
            helperText={formik.touched.group_type && formik.errors.group_type}
            select>
            {developmentGroupTypeOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant='outlined'
            fullWidth
            margin='dense'
            id='group_name'
            name='group_name'
            label={t('development_group_name')}
            value={formik.values.group_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.group_name && Boolean(formik.errors.group_name)
            }
            helperText={formik.touched.group_name && formik.errors.group_name}
          />
          {/* <Button
            margin='dense'
            color='primary'
            variant='contained'
            fullWidth
            style={{ marginTop: '10x' }}
            type='submit'>
            Submit
          </Button> */}
        </form>
      )}
    </div>
  )
}

export default DevelopmentGroupForm
