import i18n from "i18next";
import "intl-pluralrules";
import { initReactI18next } from "react-i18next";
import enTranslation from "../locales/en.json";
import amTranslation from "../locales/am.json";
import React from "react";

// Register the locale data for the 'en' locale
const resources = {
  en: { translation: enTranslation },
  am: { translation: amTranslation },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
