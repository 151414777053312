/* eslint-disable react/prop-types */
import React from "react";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import {
  fetchRegions,
  fetchWoredas,
  fetchZones,
  // fetchKebeles,
  submitReportUserForm,
  // getDaProfile,
  // getDaProfileFromDaRegistry,
  // verifyBotUser,
} from "../services/api_services";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../utils/isEmpty";
import { parsePhoneNumber } from "../utils/parsePhoneNumber";
import { bot_token } from "../services/constants";

const ReportUserForm = ({ isUpdate = false }) => {
  const { t, i18n } = useTranslation();

  const genderOptions = [
    {
      value: "male",
      label: t("male"),
    },
    {
      value: "female",
      label: t("female"),
    },
  ];

  const validationSchema = yup.object({
    name: yup.string().required(t("required")),
    phone_no: yup
      .string()
      .matches(/^(09|07)\d{8}$/, t("phone_number_validation"))
      .required(t("required")),
    gender: yup.string().required(t("required")),
    region: yup.string().required(t("required")),
    zone: yup.string().required(t("required")),
    woreda: yup.string().required(t("required")),
    // kebele: yup.string().required(t("required")),
  });
  const tele = window.Telegram.WebApp;
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  // const user_id = params.get("user_id");
  // const phone_no = params.get("phone_no");
  const lang = params.get("lang");
  const update = params.get("update");
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: "",
    phone_no: "",
    gender: "",
    region: "",
    zone: "",
    woreda: "",
    // kebele: "",
  });
  const [isPopulatingProfile, setIsPopulatingProfile] = useState(isUpdate);
  const [regionOptions, setRegionOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [woredaOptions, setWoredaOptions] = useState([]);
  // const [kebeleOptions, setKebeleOptions] = useState([]);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [fetchedProfileData, setFetchedProfileData] = useState("");

  // const fetchProfile = async () => {
  //   setIsPopulatingProfile(true);
  //   var data = {};
  //   const responseOutBound = await getDaProfile({
  //     phone_no: parsePhoneNumber(phone_no),
  //   });

  //   if (responseOutBound.status == 200) {
  //     if (!isEmpty(responseOutBound.data) && !isEmpty(responseOutBound.data.results)) {
  //       data = responseOutBound.data.results[0];
  //     } else {
  //       const responseDaRegsitry = await getDaProfileFromDaRegistry({
  //         phone_no: parsePhoneNumber(phone_no),
  //       });
  //       if (responseDaRegsitry.status == 200) {
  //         if (!isEmpty(responseOutBound.data)) {
  //           data = responseDaRegsitry.data;
  //         }
  //       }
  //     }
  //   }

  //   if (!isEmpty(data)) {
  //     setFetchedProfileData(data);
  //   }
  //   if (!isEmpty(data.region)) {
  //     fetchZones({
  //       selectedRegionId: data.region.id,
  //       setZoneOptions: setZoneOptions,
  //       setIsLoading: setIsLoading,
  //     });
  //   }

  //   if (!isEmpty(data.zone)) {
  //     fetchWoredas({
  //       selectedZoneId: data.zone.id,
  //       setWoredaOptions: setWoredaOptions,
  //       setIsLoading: setIsLoading,
  //     });
  //   }

  //   // TODO: fix this

  //   // if (!isEmpty(data.woreda)) {
  //   //   fetchKebeles({
  //   //     selectedWoredaId: data.woreda.id,
  //   //     setKebeleOptions: setKebeleOptions,
  //   //     setIsLoading: setIsLoading,
  //   //   });
  //   // }

  //   // var kebele = "";

  //   // if (isUpdate) {
  //   //   if (typeof data.kebele === "object") {
  //   //     kebele = data.kebele.id;
  //   //   } else {
  //   //     kebele = data.kebele;
  //   //   }
  //   // }

  //   setInitialValues({
  //     name: !isEmpty(data.name) ? data.name : !isEmpty(data.name) ? data.name : "",
  //     phone_no: !isEmpty(data.phone_no) ? `0${data.phone_no}` : "",
  //     gender: !isEmpty(data.gender) ? data.gender.toLowerCase() : !isEmpty(data.sex) ? data.sex : "",
  //     region: !isEmpty(data.region) ? data.region.id : "",
  //     zone: !isEmpty(data.zone) ? data.zone.id : "",
  //     woreda: !isEmpty(data.woreda) ? data.woreda.id : "",
  //     // kebele: kebele,
  //     created_by: user_id,
  //   });

  //   setIsPopulatingProfile(false);
  // };

  useEffect(() => {
    tele.expand();
    tele.MainButton.text = isUpdate ? t("update") : t("register");
    tele.MainButton.show();
    tele.MainButton.onClick(formik.handleSubmit);
    fetchRegions({
      setRegionOptions: setRegionOptions,
      setIsLoading: setIsLoading,
      setError: setError,
    });

    tele.ready();
  }, [tele]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const user_id = window.Telegram.WebApp.initDataUnsafe.user.id;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const formData = {
          name: values.name,
          phone_no: parsePhoneNumber(values.phone_no),
          gender: values.gender,
          woreda: values.woreda,
          created_by: user_id,
        };

        setIsLoading(true);

        const submitArgs = {
          update: update,
          body: formData,
          setIsLoading: setIsLoading,
        };

        if (update && !isEmpty(fetchedProfileData.id)) {
          submitArgs.id = fetchedProfileData.id;
        }

        const response = await submitReportUserForm(submitArgs);

        handleResponse(response);

        setIsLoading(false);

        if (isSuccessStatus(response)) {
          const message = `\n${t("registration_successful")}\n\n\t 👤 Name: ${values.name}\n\t 📱 Phone Number: ${
            values.phone_no
          }`;
          await sendDataToBot(message);
          tele.close();
        }
      } catch (error) {
        setIsLoading(false);
        return error;
      }
    },
  });

  // Assuming you have received the query_id when the WebApp was opened
  const queryId = window.Telegram.WebApp.initDataUnsafe.query_id;

  const sendDataToBot = async (data) => {
    const response = await fetch(`https://api.telegram.org/bot${bot_token}/answerWebAppQuery`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        web_app_query_id: queryId,
        result: {
          type: "article",
          id: queryId,
          title: "Data received",
          input_message_content: {
            message_text: data, // Sending minimal content
          },
        },
      }),
    });
  };

  const handleResponse = (response) => {
    if (isSuccessStatus(response)) {
      showSuccessToast(response);
    } else if (isClientErrorStatus(response)) {
      showErrorToast(response);
    }
  };

  const isSuccessStatus = (response) =>
    [201, 200].includes(response.status) || [201, 200].includes(response.response?.status);

  const isClientErrorStatus = (response) =>
    [400].includes(response.status) || [400].includes(response.response?.status);

  const showSuccessToast = (response) => {
    const data = (response && response.data) || (response && response.response && response.response.data);
    toast.success(isUpdate ? "Report User updated successfully." : "Report User registered successfully.", {
      position: "top-center",
      toastId: data.id,
    });
  };

  const showErrorToast = (response) => {
    const errorData = (response && response.data) || (response && response.response && response.response.data);
    setFormErrors(errorData);
    toast.error(
      isUpdate
        ? "Error updating Report User, please check the form and try again."
        : "Error registering Report User, please check the form and try again.",
      {
        position: "top-center",
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(formErrors)) {
      for (const [key, value] of Object.entries(formErrors)) {
        if (Array.isArray(value)) {
          const errorString = value.join(", ");
          formik.setFieldError(key, errorString);
        } else {
          formik.setFieldError(key, value);
        }
      }
    }
  }, [formErrors]);

  if (isLoading || isPopulatingProfile) return <CircularProgress />;
  return (
    <div>
      {isUpdate ? <h2>{t("report_user_update_form")}</h2> : <h2>{t("report_user_registration_form")}</h2>}
      <form onSubmit={formik.handleSubmit}>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="name"
          name="name"
          label={t("Name")}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="phone_no"
          name="phone_no"
          label={t("Phone Number")}
          value={formik.values.phone_no}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone_no && Boolean(formik.errors.phone_no)}
          helperText={formik.touched.phone_no && formik.errors.phone_no}
        />

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="gender"
          name="gender"
          label={t("gender")}
          value={formik.values.gender}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          helperText={formik.touched.gender && formik.errors.gender}
          select>
          {genderOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="region"
          name="region"
          label={t("region")}
          value={formik.values.region}
          onChange={(e) => {
            fetchZones({
              selectedRegionId: e.target.value,
              setZoneOptions: setZoneOptions,
              setIsLoading: setIsLoading,
            });
            formik.setFieldValue("kebele", "");
            formik.setFieldValue("woreda", "");
            formik.setFieldValue("zone", "");
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.region && Boolean(formik.errors.region)}
          helperText={formik.touched.region && formik.errors.region}
          select>
          {regionOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="zone"
          name="zone"
          label={t("zone")}
          value={formik.values.zone}
          disabled={formik.values.region.length < 1}
          onChange={(e) => {
            fetchWoredas({
              selectedZoneId: e.target.value,
              setWoredaOptions: setWoredaOptions,
              setIsLoading: setIsLoading,
            });
            formik.setFieldValue("kebele", "");
            formik.setFieldValue("woreda", "");
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.zone && Boolean(formik.errors.zone)}
          helperText={formik.touched.zone && formik.errors.zone}
          select>
          {zoneOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="woreda"
          name="woreda"
          label={t("woreda")}
          value={formik.values.woreda}
          disabled={formik.values.zone.length < 1}
          onChange={(e) => {
            // fetchKebeles({
            //   selectedWoredaId: e.target.value,
            //   setKebeleOptions: setKebeleOptions,
            //   setIsLoading: setIsLoading,
            // });
            // formik.setFieldValue("kebele", "");
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.woreda && Boolean(formik.errors.woreda)}
          helperText={formik.touched.woreda && formik.errors.woreda}
          select>
          {woredaOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {/* <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="kebele"
          name="kebele"
          label={t("kebele")}
          value={formik.values.kebele}
          disabled={formik.values.woreda.length < 1}
          onChange={(value) => {
            formik.setFieldValue("kebele", value.target.value);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.kebele && Boolean(formik.errors.kebele)}
          helperText={formik.touched.kebele && formik.errors.kebele}
          select>
          {kebeleOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField> */}
      </form>
    </div>
  );
};

export default ReportUserForm;
