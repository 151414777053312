/* eslint-disable react/prop-types */
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "../utils/isEmpty";
import {
  getDevGroupById,
  getFarmersUsingDevGroup,
  recordReach,
} from "../services/api_services";
import AdvisoriesForm from "./AdvisoriesForm";
import { toast } from "react-toastify";
import axios from "axios";
import { bot_token } from "../services/constants";
import { useTranslation } from "react-i18next";

const DevelopmentGroupList = ({
  farmers_with_dev_group,
  isDevelopmentGroupLoading,
  setBody,
  body,
}) => {
  const { t } = useTranslation();

  const handleFarmerClicked = (e, id) => {
    var b = body.dev_groups;
    const index = b[id].indexOf(e.target.value);
    if (index == -1 && e.target.checked) {
      b[id].push(e.target.value);
    } else if (index != -1 && !e.target.checked) {
      b[id] = b[id].filter((value) => value !== e.target.value);
    }
    setBody((prevBody) => ({ ...prevBody, dev_groups: b }));
  };

  const getFullName = (farmer) => {
    var farmer_name = !isEmpty(farmer.farmer_name)
      ? farmer.farmer_name != "nan"
        ? farmer.farmer_name
        : ""
      : "";
    var father_name = !isEmpty(farmer.father_name)
      ? farmer.father_name != "nan"
        ? farmer.father_name
        : ""
      : "";
    var grand_father_name = !isEmpty(farmer.grand_father_name)
      ? farmer.grand_father_name != "nan"
        ? farmer.grand_father_name
        : ""
      : "";

    return `${farmer_name} ${father_name} ${grand_father_name}`;
  };

  if (isDevelopmentGroupLoading) return <CircularProgress />;
  return (
    <div>
      <Typography
        variant='h5'
        py={2}>
        {t('reach_adoption')}
      </Typography>
      <Typography
        variant='h6'
        py={1}
        sx={{ textAlign: 'left' }}>
        {t('select_farmers_in_development_groups')}
      </Typography>
      {farmers_with_dev_group.map((farmer_with_dev_group, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1-content'
            id='panel1-header'>
            <Typography>
              {farmer_with_dev_group &&
                farmer_with_dev_group.development_group &&
                farmer_with_dev_group.development_group.group_name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {farmer_with_dev_group.farmers.length > 0 ? (
              <FormGroup>
                {farmer_with_dev_group.farmers.map((farmer, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        onClick={(e) =>
                          handleFarmerClicked(
                            e,
                            farmer_with_dev_group.development_group.id
                          )
                        }
                      />
                    }
                    label={getFullName(farmer)}
                    value={farmer.id}
                  />
                ))}
              </FormGroup>
            ) : (
              <div>{t('farmers_not_found')}</div>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
};

const RecordReachForm = () => {
  const { t } = useTranslation();
  const tele = window.Telegram.WebApp;
  const [body, setBody] = useState({});
  const [advisoryBody, setAdvisoryBody] = useState({});
  const [selectedDevGroupIds, setSelectedDevGroupIds] = useState([]);
  const [isDevelopmentGroupLoading, setIsDevelopmentGroupLoading] =
    useState(true);
  const [isAdding, setIsAddingLoading] = useState(false);
  const [farmers, setFarmers] = useState({});

  let query = new URLSearchParams(useLocation().search);
  let chatid = query.get("chatid");
  let user_id = query.get("user_id");
  let selectedids = query.get("selectedids");

  const getFarmersWithDevGroup = async () => {
    var farmersBody = {};
    var ids = selectedids.split(",");
    setSelectedDevGroupIds(ids);
    var farmers = [];
    for (var id in ids) {
      farmersBody[ids[id]] = [];
      var farmers_res = await getFarmersUsingDevGroup(ids[id]);
      var dev_group_res = await getDevGroupById(ids[id]);

      if (farmers_res.status == 200) {
        farmers.push({
          development_group: dev_group_res && dev_group_res.data,
          farmers: farmers_res.data,
        });
      }
    }
    setBody((prevBody) => ({ ...prevBody, dev_groups: farmersBody }));
    setFarmers(farmers);
    setIsDevelopmentGroupLoading(false);
  };

  const getLastValues = (obj) => {
    const values = [];

    const traverse = (data) => {
      if (Array.isArray(data)) {
        data.forEach((item) => traverse(item));
      } else if (typeof data === "object") {
        for (const key in data) {
          traverse(data[key]);
        }
      } else {
        values.push(data);
      }
    };
    traverse(obj);
    return values;
  };

  const mergeArrays = (obj) => {
    let mergedArray = [];

    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        mergedArray = mergedArray.concat(obj[key]);
      }
    }

    return mergedArray;
  };
  const sendMessageBackToBot = async (message) => {
    var url = `https://api.telegram.org/bot${bot_token}/sendMessage?text=${message}&chat_id=${chatid}`;

    try {
      const response = await axios.post(url);

      if (response.status == 200) {
        await tele.close();
      }
    } catch (error) {
      toast.error(t("error_sending_message_to_bot"), {
        position: "top-center",
      });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = async () => {
    setIsAddingLoading(true);

    var advisory_array = getLastValues(advisoryBody);
    const farmers_array = mergeArrays(body["dev_groups"]);

    if (farmers_array.length === 0) {
      toast.error(t("please_select_atleast_one_farmer"), {
        position: "top-center",
      });
      setIsAddingLoading(false);

      return;
    }
    let allEmptyStrings = advisory_array.every((value) => value === "");
    if (allEmptyStrings) {
      toast.error(t("please_select_atleast_one_advisory"), {
        position: "top-center",
      });
      setIsAddingLoading(false);

      return;
    }

    let addedCount = 0;
    let failedCount = 0;
    const promises = [];
    advisory_array.forEach((adv) => {
      farmers_array.forEach((farmer) => {
        promises.push(
          recordReach(adv, farmer, user_id)
            .then((reach_res) => {
              if (reach_res.status === 200 || reach_res.status === 201) {
                addedCount++;
              } else if (reach_res.response.status === 400) {
                failedCount++;
              }
            })
            .catch((error) => {
              console.error("Error occurred:", error);
              failedCount++;
            })
        );
      });
    });

    await Promise.all(promises);

    setIsAddingLoading(false);

    console.log("addedCount : ", addedCount);
    console.log("failedCount : ", failedCount);

    sendMessageBackToBot(t("reach_recorded_successfully"));
  };

  useEffect(() => {
    if (!isEmpty(selectedids)) {
      getFarmersWithDevGroup();
    } else {
      setSelectedDevGroupIds([]);
      setIsDevelopmentGroupLoading(false);
    }
  }, []);

  useEffect(() => {
    tele.expand();
    tele.MainButton.text = t("submit");
    tele.MainButton.show();
    tele.ready();
  }, [tele, handleSubmit]);

  useEffect(() => {
    window.Telegram.WebApp.onEvent("mainButtonClicked", handleSubmit);
    return () => {
      window.Telegram.WebApp.offEvent("mainButtonClicked", handleSubmit);
    };
  }, [handleSubmit]);

  return (
    <div>
      <DevelopmentGroupList
        devGroupIds={selectedDevGroupIds}
        isDevelopmentGroupLoading={isDevelopmentGroupLoading}
        farmers_with_dev_group={farmers}
        setBody={setBody}
        body={body}
      />
      <Typography variant="h6" pt={4} px={2} sx={{ textAlign: "left" }}>
        {t("select_value_chain_category")}
      </Typography>
      <AdvisoriesForm body={advisoryBody} setBody={setAdvisoryBody} />
      {/* <Button
        margin="dense"
        color="primary"
        variant="contained"
        fullWidth
        style={{ marginTop: "10x" }}
        onClick={handleSubmit}>
        Submit
      </Button> */}
      {isAdding ? <CircularProgress /> : <></>}
    </div>
  );
};

export default RecordReachForm;
