import axios from "axios";
import { base_url } from "./constants";

const axiosInstance = axios.create({
  baseURL: base_url,
  headers: {},
});

// Location-Specific Notifications

const createLocationSpecificNotification = async ({ body }) => {
  try {
    console.log("body : ", body);
    const response = await axiosInstance.post(
      "/api/location-notifications/",
      body
    );

    console.log("response : ", response);
    return response;
  } catch (error) {
    console.log("error : ", error);
    return error;
  }
};

const getLocationSpecificNotifications = async ({ user_id }) => {
  try {
    const response = await axiosInstance.get(
      "/api/location-notifications/by-creator/" + user_id
    );

    return response;
  } catch (error) {
    return error;
  }
};
const getNotificationById = async (id) => {
  try {
    const response = await axiosInstance.get(
      "/api/location-notifications/" + id + "/details"
    );

    return response;
  } catch (error) {
    return error;
  }
};

const updateLocationSpecificNotification = async ({ id, body }) => {
  try {
    const response = await axiosInstance.put(
      "/api/location-notifications/" + id + "/update-settings/",
      body
    );

    return response;
  } catch (error) {
    return error;
  }
};

const deleteLocationSpecificNotification = async ({ id }) => {
  try {
    const response = await axiosInstance.post(
      "/api/location-notifications" + id
    );

    return response;
  } catch (error) {
    return error;
  }
};

// Bulk Notifications

const createBulkNotification = async ({ body }) => {
  try {
    const response = await axiosInstance.post("/api/bulk-notifications", body);

    return response;
  } catch (error) {
    return error;
  }
};

const getBulkNotifications = async ({ id }) => {
  try {
    const response = await axiosInstance.get("/api/bulk-notifications");

    return response;
  } catch (error) {
    return error;
  }
};

const updateBulkNotification = async ({ id, body }) => {
  try {
    const response = await axiosInstance.post(
      "/api/bulk-notifications" + id,
      body
    );

    return response;
  } catch (error) {
    return error;
  }
};

const deleteBulkNotification = async ({ id }) => {
  try {
    const response = await axiosInstance.post("/api/bulk-notifications" + id);

    return response;
  } catch (error) {
    return error;
  }
};

export {
  createLocationSpecificNotification,
  getLocationSpecificNotifications,
  updateLocationSpecificNotification,
  deleteLocationSpecificNotification,
  createBulkNotification,
  getBulkNotifications,
  updateBulkNotification,
  deleteBulkNotification,
  getNotificationById,
};
