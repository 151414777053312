/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../utils/isEmpty";
import {
  createFeedback,
  getAdvisoryById,
  getFeedbackQuestions,
} from "../services/api_services";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { bot_token } from "../services/constants";

const QuestionComponent = ({ data, updateAnswers }) => {
  const [body, setBody] = useState({
    question_id: data.id,
    answer: data.type == "SELECT_MULTIPLE" ? [] : "",
  });
  const handleRatingAnswerChoiceChanged = (value) => {
    setBody((prevState) => ({
      ...prevState,
      answer: value,
    }));
  };

  const handleAnswerChoiceChanged = (event) => {
    const value = event.target.value;

    if (data.type == "SELECT_MULTIPLE") {
      let updatedAnswers = [...body.answer];

      if (event.target.checked) {
        updatedAnswers.push(value);
      } else {
        updatedAnswers = updatedAnswers.filter((item) => item !== value);
      }

      setBody((prevState) => ({
        ...prevState,
        answer: { choice: updatedAnswers },
      }));
    } else if (data.type == "SELECT_ONE") {
      setBody((prevState) => ({
        ...prevState,
        answer: { choice: value },
      }));
    } else if (data.type == "RATING") {
      setBody((prevState) => ({
        ...prevState,
        answer: { rating: value },
      }));
    } else if (data.type == "TEXT") {
      setBody((prevState) => ({
        ...prevState,
        answer: { text: value },
      }));
    } else {
      setBody((prevState) => ({
        ...prevState,
        answer: value,
      }));
    }
  };

  useEffect(() => {
    updateAnswers(body);
  }, [body]);

  const [rating, setRating] = useState(0);
  console.log("data : ", data);
  return (
    <Card sx={{ border: 1, borderColor: "grey.200", p: "15px", my: "10px" }}>
      <Typography sx={{ pb: "10px" }} variant="h6" align="left">
        {data.question}
      </Typography>
      <Divider />
      <Box
        sx={{
          alignContent: "center",
          pt: "5px",
          display: "flex",
          flexDirection: "column",
        }}>
        {data.type == "SELECT_MULTIPLE" ? (
          <FormControl>
            {data.choices &&
              data.choices.map((choice) => (
                <FormControlLabel
                  key={choice.id}
                  control={<Checkbox />}
                  label={choice.name}
                  value={choice.id}
                  onChange={handleAnswerChoiceChanged}
                />
              ))}
          </FormControl>
        ) : data.type == "SELECT_ONE" ? (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group">
              {data.choices &&
                data.choices.map((choice) => (
                  <FormControlLabel
                    key={choice.id}
                    control={<Radio />}
                    label={choice.name}
                    value={choice.id}
                    onChange={handleAnswerChoiceChanged}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        ) : data.type == "RATING" ? (
          <Rating
            size="large"
            name="simple-controlled"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
              handleRatingAnswerChoiceChanged(newValue);
            }}
          />
        ) : data.type == "TEXT" ? (
          <TextField
            id="detail"
            label="Detail"
            multiline
            rows={2}
            onChange={handleAnswerChoiceChanged}
          />
        ) : (
          <></>
        )}
      </Box>
    </Card>
  );
};
const delay = (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration));

const FeedbackForm = () => {
  const [questions, setQuestions] = useState([]);
  const [answer, setAnswer] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [error, setError] = useState("");
  let query = new URLSearchParams(useLocation().search);
  let chat_id = query.get("chat_id");
  let user_id = query.get("user_id");
  let advisory_id = query.get("advisory_id");
  const tele = window.Telegram.WebApp;

  const updateAnswers = (answerData) => {
    setAnswer((prevAnswers) => ({
      ...prevAnswers,
      [answerData.question_id]: answerData,
    }));
  };

  const sendMessageBackToBot = async (message) => {
    var url = `https://api.telegram.org/bot${bot_token}/sendMessage?text=${message}&chat_id=${chat_id}`;

    try {
      const response = await axios.post(url);

      if (response.status == 200) {
        await tele.close();
      }
    } catch (error) {
      toast.error("Error sending message to bot, please try again", {
        position: "top-center",
      });
    }
  };

  const handleSubmit = useCallback(async () => {
    try {
      const body = [];
      const values = Object.values(answer);
      values.map((v) => {
        if (!isEmpty(v.answer)) {
          body.push(v);
        }
      });

      if (isEmpty(body)) {
        toast.error("Please select atleast 1 question to give your feedback", {
          position: "top-center",
        });
        return;
      }
      setAddLoading(true);

      let addedCount = 0;
      let failedCount = 0;
      const promises = [];

      body.forEach((feedback_response) => {
        return promises.push(
          createFeedback({
            body: {
              user: user_id,
              response: feedback_response.answer,
              feedback_question: feedback_response.question_id,
            },
          })
            .then((reach_res) => {
              if (reach_res.status === 200 || reach_res.status === 201) {
                addedCount++;
              } else if (reach_res.response.status === 400) {
                failedCount++;
              }
            })
            .catch((error) => {
              console.error("Error occurred:", error);
              failedCount++;
            })
        );
      });

      await Promise.all(promises);

      console.log("addedCount : ", addedCount);
      console.log("failedCount : ", failedCount);

      if (addedCount > 0) {
        sendMessageBackToBot("Feedback sent successfully");
      } else if (failedCount > 0) {
        toast.error("Error adding feedback, please try again.", {
          position: "top-center",
        });
        sendMessageBackToBot("Error adding feedback, please try again.");
      }
      setAddLoading(false);
    } catch (e) {
      sendMessageBackToBot("Feedback sent successfully");
      setAddLoading(false);
    }
  }, [answer, user_id]);

  const getQuestions = async () => {
    try {
      const response = await getAdvisoryById(advisory_id);
      if (response.status == 201 || response.status == 200) {
        if (!isEmpty(response.data)) {
          if (!isEmpty(response.data.results)) {
            if (!isEmpty(response.data.results[0].feedback_questions)) {
              setQuestions(response.data.results[0].feedback_questions);
            } else {
              setQuestions([]);
            }
          }
        }
      } else if (response.status == 404) {
        setQuestions([]);
        setError("Advisory not found");
      } else {
        setQuestions([]);
        setError("Error fetching questions");
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.Telegram.WebApp.onEvent("mainButtonClicked", handleSubmit);
    return () => {
      window.Telegram.WebApp.offEvent("mainButtonClicked", handleSubmit);
    };
  }, [handleSubmit]);

  useEffect(() => {
    setIsLoading(true);
    tele.expand();
    tele.MainButton.text = "Submit";
    tele.MainButton.show();
    tele.ready();
    getQuestions();
  }, []);

  useEffect(() => {
    console.log("questions : ", questions);
  }, [questions]);

  if (isLoading) return <CircularProgress />;
  if (isEmpty(questions))
    return <h3>Feedback question not found for this advisory</h3>;

  return (
    <div>
      <h2>Feedback</h2>
      {isEmpty(error) &&
        questions.map((question) => (
          <QuestionComponent
            key={question.id}
            data={question}
            setAnswer={setAnswer}
            updateAnswers={updateAnswers}
          />
        ))}

      {isEmpty(error) ? (
        <Button
          disabled={addLoading}
          variant="contained"
          onClick={handleSubmit}>
          {addLoading ? <CircularProgress /> : "Submit"}
        </Button>
      ) : (
        <Button variant="contained" onClick={() => {}}>
          Try Again
        </Button>
      )}
    </div>
  );
};

export default FeedbackForm;
