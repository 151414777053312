import axios from "axios";
import { base_url } from "./constants";

const axiosInstance = axios.create({
  baseURL: base_url,
  headers: {
    // Add either the `ngrok-skip-browser-warning` header or a custom User-Agent
    // "ngrok-skip-browser-warning": "Please don't show me a warning!",
    // OR
  },
});

const fetchRegions = async ({
  setRegionOptions,
  regionOptions = [],
  setIsLoading,
  setError,
}) => {
  try {
    setIsLoading(true);

    const response = await axiosInstance.get("/regions/?paginate=false");
    var regionsJson = regionOptions;
    response.data.map((r) => {
      regionsJson.push({ value: r.id, label: r.name });
    });
    setRegionOptions(regionsJson);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    setError(error);
    return error;
  }
};

const fetchZones = async ({
  selectedRegionId,
  setZoneOptions,
  setIsLoading,
  zoneOptions = [],
}) => {
  try {
    // setIsLoading(true);
    const response = await axiosInstance.get(
      selectedRegionId
        ? `/zones/?region=${selectedRegionId}&paginate=false`
        : "/zones/?paginate=false"
    );
    var zonesJson = zoneOptions;
    response.data.map((r) => {
      zonesJson.push({ value: r.id, label: r.name });
    });
    setZoneOptions(zonesJson);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    return error;
  }
};

const fetchWoredas = async ({
  selectedZoneId,
  setWoredaOptions,
  setIsLoading,
  woredaOptions = [],
}) => {
  try {
    // setIsLoading(true);
    const response = await axiosInstance.get(
      selectedZoneId
        ? `/woredas/?zone=${selectedZoneId}&paginate=false`
        : "/woredas/?paginate=false"
    );
    var woredasJson = woredaOptions;
    response.data.map((r) => {
      woredasJson.push({ value: r.id, label: r.name });
    });
    setWoredaOptions(woredasJson);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    return error;
  }
};

const fetchKebeles = async ({
  selectedWoredaId,
  setKebeleOptions,
  setIsLoading,
  kebeleOptions = [],
}) => {
  try {
    // setIsLoading(true);
    const response = await axiosInstance.get(
      selectedWoredaId
        ? `/kebeles/?woreda=${selectedWoredaId}&paginate=false`
        : "/kebeles/?paginate=false"
    );
    var kebelesJson = kebeleOptions;
    response.data.map((r) => {
      kebelesJson.push({ value: r.id, label: r.name });
    });
    setKebeleOptions(kebelesJson);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    return error;
  }
};

const fetchDevelopmentGroups = async ({ kebele_id, setDevelopmentGroupOptions, setIsLoading, setError }) => {
  try {
    setIsLoading(true);
    const response = await axiosInstance.get(
      kebele_id != ""
        ? `/api/development-groups/?kebele=${kebele_id}&paginate=false`
        : "/api/development-groups/?paginate=false"
    );
    var regionsJson = [];
    response.data &&
      response.data.map((r) => {
        regionsJson.push({
          value: r.id,
          label: r.group_name,
          created_by: r.created_by,
        });
      });
    setDevelopmentGroupOptions(regionsJson);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    setError(error);
    return error;
  }
};
const addDevelopmentGroup = async ({ body }) => {
  try {
    const response = await axiosInstance.post(`/api/development-groups/`, body);

    return response;
  } catch (error) {
    return error;
  }
};
const registerFarmer = async ({ body, setIsLoading }) => {
  try {
    setIsLoading(true);
    const response = await axiosInstance.post(`/api/farmer-profiles/`, body);

    setIsLoading(false);
    return response;
  } catch (error) {
    setIsLoading(false);
    return error;
  }
};
const registerDa = async ({ body, setIsLoading }) => {
  try {
    setIsLoading(true);
    const response = await axiosInstance.post(`/api/da-profiles/`, body);

    setIsLoading(false);
    return response;
  } catch (error) {
    console.log("error : ", error);
    setIsLoading(false);
    return error;
  }
};
const updateDa = async ({ body, id, setIsLoading }) => {
  try {
    setIsLoading(true);
    const response = await axiosInstance.put(`/api/da-profiles/${id}/`, body);

    setIsLoading(false);
    return response;
  } catch (error) {
    setIsLoading(false);
    return error;
  }
};

const submitReportUserForm = async ({ update, body, setIsLoading, id = null }) => {
  try {
    console.log("body : ", body);
    setIsLoading(true);
    let response;
    if (update) {
      response = await axiosInstance.put(`/api/report-users/${id}/`, body);
    } else {
      response = await axiosInstance.post(`/api/report-users/`, body);
    }

    setIsLoading(false);
    return response;
  } catch (error) {
    setIsLoading(false);
    return error;
  }
};

const getFeedbackQuestions = ({ advisory_id }) => {
  return {
    status: 201,
    data: [
      {
        type: "RATING",
        id: "1",
        question: "How do you rate this answer?",
      },
      {
        type: "SELECT_MULTIPLE",
        id: "2",
        question: "Please select one or more issues",
        answer_choices: [
          {
            text: "Incorrect Information",
            id: "1",
          },
          {
            text: "Incomplete Content",
            id: "2",
          },
          {
            text: "No Relevant Answers",
            id: "3",
          },
          {
            text: "Difficulty Level",
            id: "4",
          },
        ],
      },
      {
        type: "TEXT",
        id: "3",
        question: "Please provide Details",
      },
    ],
    // data: [
    //   {
    //     type: "SELECT_MULTIPLE",
    //     id: "1",
    //     question: "Question one Muliple",
    //     answer_choices: [
    //       {
    //         text: "Incomplete Content",
    //         id: "1",
    //       },
    //       {
    //         text: "Difficulty Level",
    //         id: "2",
    //       },
    //       {
    //         text: "No Relevant Answers",
    //         id: "3",
    //       },
    //     ],
    //   },
    //   {
    //     type: "SELECT_ONE",
    //     id: "2",
    //     question: "Question Two",
    //     answer_choices: [
    //       {
    //         text: "Incomplete Content",
    //         id: "1",
    //       },
    //       {
    //         text: "Difficulty Level",
    //         id: "2",
    //       },
    //       {
    //         text: "No Relevant Answers",
    //         id: "3",
    //       },
    //     ],
    //   },
    //   {
    //     type: "TEXT",
    //     id: "3",
    //     question: "Write some stuff",
    //   },
    //   {
    //     type: "RATING",
    //     id: "4",
    //     question: "Rate this advisory",
    //   },
    // ],
  };
};
const createFeedback = async ({ body }) => {
  try {
    const response = await axiosInstance.post(`/api/feedback/`, body);

    return response;
  } catch (error) {
    return error;
  }
};

const getDaProfile = async ({ phone_number }) => {
  try {
    const response = await axiosInstance.get(`/api/da-profiles/`, {
      params: {
        phone_number: phone_number,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getDaProfileFromDaRegistry = async ({ phone_number }) => {
  try {
    const response = await axiosInstance.get(`/development-agents/`, {
      params: {
        phone_number: phone_number,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const updateDaProfile = async ({ body, da_id }) => {
  try {
    const response = await axiosInstance.put(`/api/da-profiles/${da_id}`, body);

    return response;
  } catch (error) {
    return error;
  }
};

const getBotUser = async ({ telegram_id }) => {
  try {
    const response = await axiosInstance.get(`/api/bot-users/?telegram_id=${telegram_id}`);
    return response;
  } catch (error) {
    return error;
  }
};

const getFarmersUsingDevGroup = async (development_group_id) => {
  try {
    const response = await axiosInstance.get(
      `/api/farmer-profiles/?outbound_development_group=${development_group_id}&paginate=false`
    );
    return response;
  } catch (error) {
    return error;
  }
};

const getDevGroupById = async (development_group_id) => {
  try {
    const response = await axiosInstance.get(`/api/development-groups/${development_group_id}/`);
    return response;
  } catch (error) {
    return error;
  }
};

const getValueChainCategories = async () => {
  try {
    const response = await axios.get(`${base_url}/api/value-chain-categories/`);
    return response;
  } catch (error) {
    return error;
  }
};

const getValueChains = async (category_id, kebele) => {
  try {
    console.log("getValueChains : ", kebele);
    const response = await axiosInstance.get(`/api/value-chains/${category_id}/?kebele_id=${kebele}`);
    return response;
  } catch (error) {
    return error;
  }
};

const getPractices = async (value_chain_uuid, kebele) => {
  try {
    const response = await axios.get(
      `${base_url}/api/practices/?value_chain_id=${value_chain_uuid}&kebele_id=${kebele}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

const getSubPractices = async (practice_uuid) => {
  try {
    const response = await axios.get(`${base_url}/api/sub-practices/subpractices_by_practice/${practice_uuid}/`);
    return response;
  } catch (error) {
    return error;
  }
};

const verifyBotUser = async ({ id, status }) => {
  try {
    const response = await axiosInstance.patch(`/api/bot-users/${id}/`, {
      verification_status: status,
    });
    return response;
  } catch (error) {
    return error;
  }
};

const fetchRoles = async ({ setRoleOptions, setIsLoading, setError }) => {
  try {
    setIsLoading(true);

    const response = await axios.get(base_url + "/api/da-roles/");
    var rolesJson = [];
    response.data.map((r) => {
      rolesJson.push({ value: r.id, label: r.name });
    });
    setRoleOptions(rolesJson);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    setError(error);
    return error;
  }
};

const getAdvisories = async (value_chain_uuid, sub_practice_uuid, location_name, location_id) => {
  try {
    const url = `${base_url}/api/advisory/?value_chain=${value_chain_uuid}&sub_practice=${sub_practice_uuid}&location={"name":"${location_name}","id":"${location_id}"}`;

    const response = await axios.get(url);
    return response;
  } catch (error) {
    return error;
  }
};
const recordReach = async (advisory_uuid, farmer_uuid, user_id) => {
  try {
    const response = await axios.post(`${base_url}/api/reach-farmers/`, {
      farmer: farmer_uuid,
      advisory: advisory_uuid,
      created_by: user_id,
    });
    return response;
  } catch (error) {
    return error;
  }
};
const recordAdoption = async (advisory_uuid, farmer_uuid, user_id) => {
  try {
    const response = await axios.post(`${base_url}/api/adoption/`, {
      farmer: farmer_uuid,
      advisory: advisory_uuid,
      created_by: user_id,
      adoption_type: "SELF_REPORTED",
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getReachedFarmersUsingDevGroup = async (development_group_id) => {
  try {
    const response = await axios.get(`${base_url}/api/farmerlist-adoption/${development_group_id}/`);
    return response;
  } catch (error) {
    return error;
  }
};

const getAdminLevel = async (sub_practice_id) => {
  try {
    const response = await axios.get(`${base_url}/api/advisoryadmin-level/${sub_practice_id}/`);
    return response;
  } catch (error) {
    return error;
  }
};

const getAdvisoryById = async (advisory_id) => {
  try {
    const response = await axios.get(`${base_url}/api/advisory/?id=${advisory_id}`);

    return response;
  } catch (error) {
    return error;
  }
};

export {
  fetchRegions,
  fetchZones,
  fetchWoredas,
  fetchKebeles,
  fetchDevelopmentGroups,
  addDevelopmentGroup,
  registerFarmer,
  registerDa,
  getFeedbackQuestions,
  createFeedback,
  getDaProfile,
  updateDaProfile,
  getBotUser,
  getFarmersUsingDevGroup,
  getValueChainCategories,
  getValueChains,
  getDevGroupById,
  getPractices,
  getDaProfileFromDaRegistry,
  verifyBotUser,
  updateDa,
  fetchRoles,
  getSubPractices,
  getAdvisories,
  recordReach,
  recordAdoption,
  getReachedFarmersUsingDevGroup,
  getAdminLevel,
  getAdvisoryById,
  submitReportUserForm,
};
