import React from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";

const NotificationListCard = ({ label, id, active, createdAt }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        width: "85vw",
        marginY: "6px",
        padding: "2px",
        maxWidth: "550px",
        "&:hover": {
          boxShadow: 5,
        },
      }}
      onClick={() => navigate(`/notification/update/${id}`)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          margin: "10px",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}>
          <Box>
            <Typography
              variant="h6"
              component="div"
              sx={{
                textAlign: "left",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}>
              {label.length > 20 ? label.slice(0, 20) + "..." : label}
            </Typography>
          </Box>
          {/* <Box>
            <Box
              sx={{
                width: "18px",
                height: "18px",
                borderRadius: "50%",
                backgroundColor: active ? "#4CAF50" : "#BDBDBD",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: "24px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box> */}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}>
          <Box>
            {createdAt && (
              <Typography color="text.secondary">
                {moment(createdAt).fromNow()}
              </Typography>
            )}
          </Box>
          <NavLink to={`/notification/update/${id}`}>
            <Button size="medium">Update</Button>
          </NavLink>
        </Box>
      </Box>
    </Card>
  );
};

export default NotificationListCard;
