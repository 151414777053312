import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import {
  fetchKebeles,
  fetchRegions,
  fetchWoredas,
  fetchZones,
} from "../../../services/api_services";
import { isEmpty } from "../../../utils/isEmpty";
import { UPDATE } from "../../../services/constants";
import {
  createLocationSpecificNotification,
  getNotificationById,
  updateLocationSpecificNotification,
} from "../../../services/notification_api_services";
import { useLocation, useParams } from "react-router-dom";

const typeOptions = [
  {
    value: "advisory",
    label: "Advisory",
  },
  {
    value: "reach",
    label: "Reach",
  },
  {
    value: "adoption",
    label: "Adoption",
  },
  {
    value: "farmer_registration",
    label: "Farmer Registration",
  },
];

const NotificationForm = ({ mode, id }) => {
  const { t } = useTranslation();
  const tele = window.Telegram.WebApp;
  let query = new URLSearchParams(useLocation().search);
  let bot_user_id = query.get("bot_user_id");

  const validationSchema = yup.object({
    name: yup.string().required(t("required")),
    type: yup.string().required(t("required")),
    region: yup.string().required(t("required")),
    message: yup.string().required(t("required")),
    send_now: yup.boolean(),
    frequency: yup.string().when("send_now", {
      is: (value) => value === false,
      then: (schema) => schema.required(t("required")),
      otherwise: (schema) => schema,
    }),
    interval: yup.string().when("send_now", {
      is: (value) => value === false,
      then: (schema) => schema.required(t("required")),
      otherwise: (schema) => schema,
    }),
  });

  const [regionOptions, setRegionOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [woredaOptions, setWoredaOptions] = useState([]);
  const [kebeleOptions, setKebeleOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingNotification, setIsAddingNotification] = useState(false);
  const [isRemovingNotification, setIsRemovingNotification] = useState(false);
  const [sendNow, setSendNow] = useState(false);
  const [error, setError] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    type: "",
    frequency: "",
    send_now: false,
    message: "",
    interval: "",
    region: "",
    zone: "",
    woreda: "",
    kebele: "",
  });

  const [open, setOpen] = React.useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setIsRemovingNotification(false);
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // GET FROM BOT SIDE
        var created_by = "";
        if (mode === UPDATE) {
          created_by = values.created_by;
        } else {
          created_by = bot_user_id;
        }

        var body = {
          name: values.name,
          message: values.message,
          created_by: created_by,
          content_type: values.type,
        };
        if (values.send_now) {
          body.send_now = values.send_now;
        }
        if (!isEmpty(values.region) && values.region !== "all") {
          body.location_type = "region";
          body.location_id = values.region;
        } else {
          body.location_type = "all";
        }
        if (!isEmpty(values.zone) && values.zone !== "all") {
          body.location_type = "zone";
          body.location_id = values.zone;
        }
        if (!isEmpty(values.woreda) && values.woreda !== "all") {
          body.location_type = "woreda";
          body.location_id = values.woreda;
        }
        if (!isEmpty(values.kebele) && values.kebele !== "all") {
          body.location_type = "kebele";
          body.location_id = values.kebele;
        }

        if (!values.send_now) {
          body.frequency = values.frequency;
          body.interval = values.interval;
        }
        setIsAddingNotification(true);

        var response;
        if (mode === UPDATE) {
          response = await updateLocationSpecificNotification({
            id: id,
            body: body,
          });
        } else {
          response = await createLocationSpecificNotification({
            body: body,
          });
        }

        const isSuccessfulResponse =
          response.status === 201 ||
          response.status === 200 ||
          response.response?.status === 201 ||
          response.response?.status === 200;

        if (isSuccessfulResponse) {
          toast.success("Notification created successfully", {
            position: "top-center",
          });

          setIsAddingNotification(false);
          await tele.close();
        } else {
          if (response?.status === 400) {
            setFormErrors(response?.data);
          } else if (response?.response?.status === 400) {
            setFormErrors(response?.response?.data);
          } else {
            toast.error(
              mode === UPDATE
                ? "Error updating notification, please check the form and try again."
                : "Error registering notification, please check the form and try again.",
              { position: "top-center" }
            );
            setIsAddingNotification(false);
          }
        }
        setIsAddingNotification(false);
      } catch (error) {
        setIsAddingNotification(false);
        return error;
      }
    },
  });

  useEffect(() => {
    if (!isEmpty(formErrors)) {
      for (const [key, value] of Object.entries(formErrors)) {
        if (Array.isArray(value)) {
          const errorString = value.join(", ");
          formik.setFieldError(key, errorString);
        } else {
          formik.setFieldError(key, value);
        }
      }
    }
  }, [formErrors]);

  const handleRemoveNotification = async () => {
    setIsRemovingNotification(true);
    const response = await updateLocationSpecificNotification({
      id: id,
      body: { active: false },
    });
    const isSuccessfulResponse =
      response.status === 201 ||
      response.status === 200 ||
      response.response?.status === 201 ||
      response.response?.status === 200;

    if (isSuccessfulResponse) {
      toast.success("Notification removed successfully", {
        position: "top-center",
      });
      await tele.close();
      setIsLoading(false);
    } else {
      if (response?.status === 400 || response.response?.status === 400) {
        setFormErrors(response?.data);
      } else {
        toast.error(
          "Error removing notification, please try again.",

          { position: "top-center" }
        );
        setIsLoading(false);
      }
    }
    handleClose();
  };

  // GET REGIONS LIST
  const [isRegionsLoading, setIsRegionsLoading] = useState(false);
  const [isZonesLoading, setIsZonesLoading] = useState(false);
  const [isWoredasLoading, setIsWoredasLoading] = useState(false);
  const [isKebelesLoading, setIsKebelesLoading] = useState(false);

  useEffect(() => {
    fetchRegions({
      setRegionOptions: setRegionOptions,
      regionOptions: [{ value: "all", label: "All" }],
      setIsLoading: setIsRegionsLoading,
      setError: setError,
    });
  }, []);

  // GET NOTIFICATION DETAIL
  const [notificationDetail, setNotificationDetail] = useState({});
  const [isNotificationLoading, setIsNotificationLoading] = useState(true);

  const getNotificationDetail = async () => {
    try {
      var response = await getNotificationById(id);
      const isSuccessfulResponse =
        response.status === 201 || response.status === 200;
      const isSuccessfulNestedResponse =
        response.response?.status === 201 || response.response?.status === 200;

      if (isSuccessfulResponse || isSuccessfulNestedResponse) {
        if (response?.data?.results) {
          setNotificationDetail(response?.data?.results);
        } else if (response?.data) {
          setNotificationDetail(response?.data);
        }
        setIsNotificationLoading(false);
      } else {
        toast.error("Error getting notification detail, please try again.", {
          position: "top-center",
        });
        setIsNotificationLoading(false);
      }
      setIsNotificationLoading(false);
    } catch (error) {
      setIsNotificationLoading(false);
      return error;
    }
  };

  useEffect(() => {
    if (mode === UPDATE) {
      getNotificationDetail();
    }
  }, [mode]);

  // POPULATE FORM FIELDS
  const getLocationId = (notificationDetail, type) => {
    if (notificationDetail && notificationDetail.location_type) {
      if (notificationDetail.location_type.toLowerCase() == type) {
        return notificationDetail.location_id;
      } else {
        return notificationDetail[`${type}_id`] == null
          ? "all"
          : notificationDetail[`${type}_id`];
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (mode === UPDATE && !isEmpty(notificationDetail)) {
      var data = {}
      formik.setFieldValue("zone", "");
      if (!isEmpty(notificationDetail.region_id)) {
        var region = getLocationId(notificationDetail, "region")
        formik.setFieldValue("region", region);

        fetchZones({
          selectedRegionId: notificationDetail.region_id,
          zoneOptions: [{ value: "all", label: "All" }],
          setZoneOptions: (zones) => {
            setZoneOptions(zones)
            var zone = getLocationId(notificationDetail, "zone")
            formik.setFieldValue("zone", zone);
          },
          setIsLoading: setIsZonesLoading,
        });
      } else {
        setIsZonesLoading(false);
      }

      if (!isEmpty(notificationDetail.zone_id)) {
        fetchWoredas({
          selectedZoneId: notificationDetail.zone_id,
          setWoredaOptions: (woredas) => {
            setWoredaOptions(woredas)
            var woreda = getLocationId(notificationDetail, "woreda")
            formik.setFieldValue("woreda", woreda);
          },
          woredaOptions: [{ value: "all", label: "All" }],
          setIsLoading: setIsWoredasLoading,
        });
      } else {
        setIsWoredasLoading(false);
      }
    
      if (!isEmpty(notificationDetail.woreda_id)) {
        data.woreda = getLocationId(notificationDetail, "woreda")
        data.kebele = getLocationId(notificationDetail, "kebele")
        fetchKebeles({
          selectedWoredaId: notificationDetail.woreda_id,
          kebeleOptions: [{ value: "all", label: "All" }],
          setKebeleOptions: (kebeles) => {
            setKebeleOptions(kebeles)
            var kebele = getLocationId(notificationDetail, "kebele")
            formik.setFieldValue("kebele", kebele);
          },
          setIsLoading: setIsKebelesLoading,
        });
      } else {
        setIsKebelesLoading(false);
      }

      if (!isEmpty(notificationDetail.kebele_id)) {
        data.kebele = getLocationId(notificationDetail, "kebele")
        fetchKebeles({
          selectedWoredaId: notificationDetail.woreda_id,
          kebeleOptions: [{ value: "all", label: "All" }],
          setKebeleOptions: setKebeleOptions,
          setIsLoading: setIsKebelesLoading,
        });
      } 
    }
  }, [mode, notificationDetail, initialValues])

  useEffect(() => {
    if (mode === UPDATE && !isEmpty(notificationDetail)) {
      var data = {
        name: notificationDetail && notificationDetail.name,
        type: notificationDetail && notificationDetail.content_type,
        frequency: notificationDetail && notificationDetail.frequency,
        send_now: notificationDetail && notificationDetail.send_now,
        message: notificationDetail && notificationDetail.message,
        interval: notificationDetail && notificationDetail.interval,
        created_by: notificationDetail && notificationDetail.created_by,
      };
      if (notificationDetail && notificationDetail.send_now) {
        setSendNow(notificationDetail.send_now);
      }

      if (!isEmpty(data)) {
        setInitialValues(data);
      }
    }
  }, [notificationDetail]);

  // INIT TELEGRAM API
  useEffect(() => {
    if (!isEmpty(regionOptions)) {
      tele.expand();
      tele.ready();
    }
  }, [tele, regionOptions]);

  if (
    isLoading ||
    isRegionsLoading ||
    (mode == UPDATE && isNotificationLoading) ||
    isZonesLoading ||
    isWoredasLoading ||
    isKebelesLoading
  )
    return <CircularProgress />;

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        variant="outlined"
        fullWidth
        margin="dense"
        id="name"
        name="name"
        label={t("name")}
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextField
        variant="outlined"
        fullWidth
        margin="dense"
        id="type"
        name="type"
        label={t("type")}
        value={formik.values.type}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.type && Boolean(formik.errors.type)}
        helperText={formik.touched.type && formik.errors.type}
        select>
        {typeOptions &&
          typeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        variant="outlined"
        fullWidth
        margin="dense"
        id="region"
        name="region"
        label={t("region")}
        value={isEmpty(regionOptions) ? "" :formik.values.region}
        onChange={(e) => {
          if (e.target.value !== "all") {
            fetchZones({
              selectedRegionId: e.target.value,
              zoneOptions: [{ value: "all", label: "All" }],
              setZoneOptions: setZoneOptions,
              setIsLoading: setIsLoading,
            });
          }
          formik.setFieldValue("kebele", "");
          formik.setFieldValue("woreda", "");
          formik.setFieldValue("zone", "");
          formik.handleChange(e);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.region && Boolean(formik.errors.region)}
        helperText={formik.touched.region && formik.errors.region}
        select>
        {regionOptions &&
          regionOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
      {formik.values.region !== "all" && !isEmpty(formik.values.region) ? (
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="zone"
          name="zone"
          label={t("zone")}
          value={isEmpty(zoneOptions) ? "" :formik.values.zone}
          onChange={(e) => {
            if (e.target.value !== "all") {
              fetchWoredas({
                selectedZoneId: e.target.value,
                woredaOptions: [{ value: "all", label: "All" }],
                setWoredaOptions: setWoredaOptions,
                setIsLoading: setIsLoading,
              });
            }
            formik.setFieldValue("kebele", "");
            formik.setFieldValue("woreda", "");
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.zone && Boolean(formik.errors.zone)}
          helperText={formik.touched.zone && formik.errors.zone}
          select>
          {zoneOptions &&
            zoneOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
      ) : (
        <></>
      )}
      {formik.values.region !== "all" &&
      formik.values.zone !== "all" &&
      !isEmpty(formik.values.zone) ? (
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="woreda"
          name="woreda"
          label={t("woreda")}
          value={isEmpty(woredaOptions) ? "" :formik.values.woreda}
          // disabled={formik.values.zone.length < 1}
          onChange={(e) => {
            if (e.target.value !== "all") {
              fetchKebeles({
                selectedWoredaId: e.target.value,
                kebeleOptions: [{ value: "all", label: "All" }],
                setKebeleOptions: setKebeleOptions,
                setIsLoading: setIsLoading,
              });
            }
            formik.setFieldValue("kebele", "");
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.woreda && Boolean(formik.errors.woreda)}
          helperText={formik.touched.woreda && formik.errors.woreda}
          select>
          {woredaOptions &&
            woredaOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
      ) : (
        <></>
      )}
      {formik.values.region !== "all" &&
      formik.values.zone !== "all" &&
      formik.values.woreda !== "all" &&
      !isEmpty(formik.values.woreda) ? (
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="kebele"
          name="kebele"
          label={t("kebele")}
          value={isEmpty(kebeleOptions) ? "" :formik.values.kebele}
          // disabled={formik.values.woreda.length < 1}
          onChange={(value) => {
            formik.setFieldValue("kebele", value.target.value);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.kebele && Boolean(formik.errors.kebele)}
          helperText={formik.touched.kebele && formik.errors.kebele}
          select>
          {kebeleOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <></>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}>
        <Box>
          <FormControlLabel
            value="send_now"
            control={
              <Switch
                color="primary"
                checked={sendNow}
                onChange={(value) => {
                  setSendNow(value.target.checked);
                  formik.setFieldValue("send_now", value.target.checked);
                }}
              />
            }
            label="Send Now"
            labelPlacement="end"
          />
        </Box>
        {!sendNow && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}>
            <FormLabel component="legend" sx={{ paddingX: 2 }}>
              Select Frequency
            </FormLabel>
            <FormControl
              component="fieldset"
              error={Boolean(
                formik.errors.frequency && formik.touched.frequency
              )}>
              <RadioGroup
                row
                value={formik.values.frequency}
                onChange={(value) => {
                  formik.setFieldValue("frequency", value.target.value);
                }}>
                <FormControlLabel
                  value="weekly"
                  control={<Radio />}
                  label="Weekly"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio />}
                  label="Monthly"
                />
              </RadioGroup>
              {formik.touched.frequency && formik.errors.frequency && (
                <FormHelperText>{formik.errors.frequency}</FormHelperText>
              )}
            </FormControl>
          </Box>
        )}
      </Box>
      {!sendNow && (
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          type="number"
          id="interval"
          name="interval"
          label={t("recurrence_interval")}
          value={formik.values.interval}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.interval && Boolean(formik.errors.interval)}
          helperText={formik.touched.interval && formik.errors.interval}
        />
      )}
      <TextField
        variant="outlined"
        fullWidth
        margin="dense"
        id="message"
        name="message"
        label={t("message")}
        value={formik.values.message}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.message && Boolean(formik.errors.message)}
        helperText={formik.touched.message && formik.errors.message}
        multiline
        rows={4}
      />
      {isAddingNotification ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            paddingY: "10px",
          }}>
          <Button
            type="submit"
            variant="contained"
            disabled={isAddingNotification}>
             {mode === UPDATE ? "Update" : "Submit"}
          </Button>
          <Button variant="contained" color="error" onClick={handleClickOpen}>
            Remove
          </Button>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to remove this notification?"}
        </DialogTitle>
        {isRemovingNotification && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}>
                <CircularProgress />
              </Box>
            </DialogContentText>
          </DialogContent>
        )}
        {!isRemovingNotification && mode == UPDATE && (
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={isRemovingNotification ? null : handleRemoveNotification}
              color="error">
              Remove
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </form>
  );
};

export default NotificationForm;
