/* eslint-disable react/prop-types */
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { isEmpty } from "../utils/isEmpty";
import {
  getPractices,
  getSubPractices,
  getValueChainCategories,
  getValueChains,
  getAdvisories,
  getAdminLevel,
} from "../services/api_services";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
}

function getAdminLevelValue(advisoryLevel) {
  let adminLevel = "";
  if (advisoryLevel !== null) {
    advisoryLevel.forEach((av) => {
      if (av.admin_level.toLowerCase() === "kebele") {
        adminLevel = av.admin_level;
      } else if (
        av.admin_level.toLowerCase() === "woreda" &&
        adminLevel !== "kebele"
      ) {
        adminLevel = av.admin_level;
      } else if (
        av.admin_level.toLowerCase() === "zone" &&
        adminLevel !== "woreda" &&
        adminLevel !== "kebele"
      ) {
        adminLevel = av.admin_level;
      } else if (
        av.admin_level.toLowerCase() === "region" &&
        adminLevel !== "zone" &&
        adminLevel !== "woreda" &&
        adminLevel !== "kebele"
      ) {
        adminLevel = av.admin_level;
      }
    });
  }
  return adminLevel;
}

const AdvisoryLabels = ({
  subPractice,
  practice,
  valueChain,
  category,
  body,
  setBody,
  isLoading,
  advisory,
  isOnlyOne,
}) => {
  const [labelCheckboxChecked, setLabelCheckboxClicked] = useState(false);

  const handleLabelCheckboxClicked = (event) => {
    setLabelCheckboxClicked(event.target.checked);

    if (event.target.checked) {
      setBody((prevState) => {
        const newState = { ...prevState };
        var data =
          newState[category["id"]][valueChain["id"]][practice["id"]][
            subPractice["id"]
          ];
        if (data.includes(event.target.value)) {
          console.log("Already selected");
        } else {
          newState[category["id"]][valueChain["id"]][practice["id"]][
            subPractice["id"]
          ] = [...data, event.target.value];
        }

        return newState;
      });
    } else {
      setBody((prevState) => {
        const newState = { ...prevState };
        var data =
          newState[category["id"]][valueChain["id"]][practice["id"]][
            subPractice["id"]
          ];

        let index = data.indexOf(event.target.value);

        if (index !== -1) {
          data.splice(index, 1);
        }

        newState[category["id"]][valueChain["id"]][practice["id"]][
          subPractice["id"]
        ] = data;

        return newState;
      });
    }
  };

  useEffect(() => {
    setBody((prevState) => {
      const newState = { ...prevState };

      newState[category["id"]][valueChain["id"]][practice["id"]][
        subPractice["id"]
      ] = [];

      return newState;
    });
    if (isOnlyOne) {
      var event = { target: { checked: true, value: advisory.id } };
      handleLabelCheckboxClicked(event);
      setLabelCheckboxClicked(true);
    }
  }, [isOnlyOne]);

  if (isOnlyOne) return;

  return (
    <Box
      key={advisory.id}
      sx={{
        display: "flex",
        alignContent: "start",
        justifyContent: "start",
        textAlign: "left",
        py: "6px",
      }}>
      <FormControlLabel
        control={
          <Checkbox
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            onClick={handleLabelCheckboxClicked}
          />
        }
        label={
          !isEmpty(advisory.label)
            ? advisory.label
            : !isEmpty(advisory.template_content)
            ? truncateString(advisory.template_content[0], 80)
            : subPractice.name
        }
        value={advisory.id}
      />
    </Box>
  );
};

const SubPractices = ({
  practice,
  valueChain,
  category,
  body,
  setBody,
  subPractice,
  isOnlyOne,
}) => {
  const { t } = useTranslation();
  const [subPracticeCheckboxChecked, setSubPracticeCheckboxClicked] =
    useState(false);

  const handleSubPracticeCheckboxClicked = (event) => {
    setSubPracticeCheckboxClicked(event.target.checked);
    if (event.target.checked) {
      getAdvisoryLabels(event.target.value);
    } else {
      setBody((prevState) => {
        const newState = { ...prevState };
        newState[category.id][valueChain.id][practice.id][event.target.value] =
          {};
        return newState;
      });
    }
  };

  // ADVISORY LABEL LOGIC
  let query = new URLSearchParams(useLocation().search);
  const [advisoryLabels, setAdvisoryLabels] = useState([]);
  const [isLabelLoading, setIsLabelLoading] = useState(false);
  const getAdvisoryLabels = async (subPracticeId) => {
    setIsLabelLoading(true);
    var admin_level = await getAdminLevel(subPracticeId);
    if (admin_level.status == 200) {
      var admin_level_value = getAdminLevelValue(admin_level["data"]);
      if (!isEmpty(admin_level_value)) {
        var advisories = await getAdvisories(
          valueChain["id"],
          subPracticeId,
          admin_level_value,
          query.get(admin_level_value)
        );
        if (advisories.status == 200) {
          setAdvisoryLabels(advisories.data.results);
        }
      }
      setIsLabelLoading(false);
    } else {
      setAdvisoryLabels([]);
    }
  };

  // ADVISORY LABEL LOGIC

  useEffect(() => {
    if (isOnlyOne) {
      getAdvisoryLabels(subPractice.id);
    }
    setSubPracticeCheckboxClicked(isOnlyOne);
  }, [isOnlyOne]);

  if (isOnlyOne)
    return (
      <div>
        {!isLabelLoading && advisoryLabels.length == 0 ? (
          <div>{t("advisory_not_found")}</div>
        ) : (
          <></>
        )}

        {subPracticeCheckboxChecked && (
          <div>
            {isLabelLoading ? (
              <CircularProgress />
            ) : (
              advisoryLabels.map((advisory) => (
                <AdvisoryLabels
                  key={advisory.id}
                  isOnlyOne={advisoryLabels.length == 1}
                  category={category}
                  valueChain={valueChain}
                  practice={practice}
                  subPractice={subPractice}
                  body={body}
                  setBody={setBody}
                  advisory={advisory}
                />
              ))
            )}
          </div>
        )}
      </div>
    );
  return (
    <Accordion
      expanded={subPracticeCheckboxChecked && advisoryLabels.length != 1}
      key={subPractice.id}
      sx={{ boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={advisoryLabels.length == 1 ? null : <ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header">
        <Typography>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                onClick={handleSubPracticeCheckboxClicked}
              />
            }
            label={subPractice.name}
            value={subPractice.id}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {subPracticeCheckboxChecked && (
          <div>
            {advisoryLabels.length == 1 ? (
              <div></div>
            ) : isLabelLoading ? (
              <CircularProgress />
            ) : isEmpty(advisoryLabels) ? (
              <div>No advisory found</div>
            ) : (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                Select Advisory
              </Typography>
            )}
            {advisoryLabels.map((advisory) => (
              <AdvisoryLabels
                isOnlyOne={advisoryLabels.length == 1}
                key={advisory.id}
                category={category}
                valueChain={valueChain}
                practice={practice}
                subPractice={subPractice}
                body={body}
                setBody={setBody}
                advisory={advisory}
                isLoading={isLabelLoading}
              />
            ))}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const Practices = ({ valueChain, category, body, setBody, practice }) => {
  const { t } = useTranslation();
  const [practiceCheckboxChecked, setPracticeCheckboxClicked] = useState(false);

  const handlePracticeCheckboxClicked = (event) => {
    setPracticeCheckboxClicked(event.target.checked);
    if (event.target.checked) {
      getSubPracticesList(event.target.value);
    } else {
      setBody((prevState) => {
        const newState = { ...prevState };
        newState[category.id][valueChain.id][event.target.value] = {};
        return newState;
      });
    }
  };
  // SUB PRACTICE LOGIC
  const [subPractices, setSubPractices] = useState([]);
  const [isSubPracticeLoading, setIsSubPracticeLoading] = useState(false);

  const getSubPracticesList = async (practice_id) => {
    setIsSubPracticeLoading(true);
    var subPracticesRes = await getSubPractices(practice_id);
    if (subPracticesRes.status == 200) {
      var subPracticeList = {};
      setSubPractices(subPracticesRes.data);
      subPracticesRes.data.map(
        (practice) => (subPracticeList[practice["id"]] = {})
      );

      setBody((prevBody) => {
        const newInnerObject = {
          ...prevBody[category["id"]][valueChain["id"]],
          [practice_id]: subPracticeList,
        };
        const newOuterObject = {
          ...prevBody[category["id"]],
          [valueChain["id"]]: newInnerObject,
        };
        const newBody = {
          ...prevBody,
          [category["id"]]: newOuterObject,
        };
        return newBody;
      });
    }
    setIsSubPracticeLoading(false);
  };
  // SUB PRACTICE LOGIC
  return (
    <Accordion
      // expanded={practiceCheckboxChecked && subPractices.length != 1}
      key={practice.id}
      sx={{ boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={subPractices.length == 1 ? null : <ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header">
        <Typography>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                onClick={handlePracticeCheckboxClicked}
              />
            }
            label={practice.name}
            value={practice.id}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {practiceCheckboxChecked && (
          <div>
            {isSubPracticeLoading || subPractices.length == 1 ? (
              <></>
            ) : (
              <Typography variant="h6" px={0} sx={{ textAlign: "left" }}>
                {t("select_sub_practice")}
              </Typography>
            )}
            {isSubPracticeLoading ? (
              <CircularProgress />
            ) : (
              subPractices &&
              subPractices.map((subPractice) => (
                <SubPractices
                  isOnlyOne={subPractices.length == 1}
                  key={subPractice.id}
                  category={category}
                  valueChain={valueChain}
                  practice={practice}
                  body={body}
                  setBody={setBody}
                  subPractice={subPractice}
                />
              ))
            )}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const ValueChains = ({ valueChain, category, body, setBody }) => {
  const { t } = useTranslation();
  const [valueChainCheckboxChecked, setValueChainCheckboxClicked] =
    useState(false);

  const handleValueChainCheckboxClicked = async (event) => {
    setValueChainCheckboxClicked(event.target.checked);
    if (event.target.checked) {
      await getPracticesList(event.target.value);
    } else {
      setBody((prevState) => {
        const newState = { ...prevState };
        newState[category.id][event.target.value] = {};
        return newState;
      });
    }
  };
  // PRACTICE LOGIC
  const [practices, setPractices] = useState([]);
  const [isPracticesLoading, setIsPracticesLoading] = useState(false);
  let query = new URLSearchParams(useLocation().search);
  let kebele = query.get("kebele");
  const getPracticesList = async (value_chain_id) => {
    setIsPracticesLoading(true);
    var practicesRes = await getPractices(value_chain_id, kebele);
    if (practicesRes.status == 200) {
      var practiceList = {};
      setPractices(practicesRes.data);
      practicesRes.data.map((practice) => (practiceList[practice["id"]] = {}));

      setBody((prevBody) => {
        const valueChainObject = {
          ...prevBody[category["id"]],
          [value_chain_id]: practiceList,
        };
        const newBody = {
          ...prevBody,
          [category["id"]]: valueChainObject,
        };
        return newBody;
      });
    }
    setIsPracticesLoading(false);
  };
  // PRACTICE LOGIC

  return (
    <Accordion key={valueChain.id} sx={{ boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header">
        <Typography>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                onClick={handleValueChainCheckboxClicked}
              />
            }
            label={valueChain.name}
            value={valueChain.id}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {valueChainCheckboxChecked && (
          <div>
            <Typography variant="h6" px={0} sx={{ textAlign: "left" }}>
              {t("select_practice")}
            </Typography>
            {isPracticesLoading ? (
              <CircularProgress />
            ) : (
              practices &&
              practices.map((practice) => (
                <Practices
                  key={practice.id}
                  category={category}
                  valueChain={valueChain}
                  body={body}
                  setBody={setBody}
                  practice={practice}
                />
              ))
            )}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const ValueChainCategories = ({ body, setBody, category }) => {
  const [categoryCheckboxChecked, setCategoryCheckboxChecked] = useState(false);
  const { t } = useTranslation();

  // VALUE CHAIN LOGIC
  const [valueChains, setValueChains] = useState([]);
  const [isValueChainLoading, setIsValueChainLoading] = useState(false);
  let query = new URLSearchParams(useLocation().search);
  let kebele = query.get("kebele");
  const getValueChainsList = async (category_id) => {
    setIsValueChainLoading(true);
    var valueChainsRes = await getValueChains(category_id, kebele);
    if (valueChainsRes.status == 200) {
      var valueChainList = {};
      setValueChains(valueChainsRes.data);
      valueChainsRes.data.map(
        (value_chain) => (valueChainList[value_chain["id"]] = {})
      );
      setBody((prevBody) => {
        const newBody = {
          ...prevBody,
          [category_id]: valueChainList,
        };

        return newBody;
      });
    }
    setIsValueChainLoading(false);
  };
  // VALUE CHAIN LOGIC

  const handleCategoryCheckboxClicked = async (event) => {
    setCategoryCheckboxChecked(event.target.checked);
    if (event.target.checked) {
      await getValueChainsList(event.target.value);
    } else {
      setBody((prevState) => {
        const newState = { ...prevState };
        newState[event.target.value] = {};
        return newState;
      });
    }
  };

  return (
    <Box sx={{ py: "4px" }}>
      <Accordion key={category.id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header">
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  onClick={handleCategoryCheckboxClicked}
                />
              }
              label={category.name}
              value={category.id}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {categoryCheckboxChecked && (
            <div>
              <Typography variant="h6" px={0} sx={{ textAlign: "left" }}>
                {t("select_value_chain")}
              </Typography>
              {isValueChainLoading ? (
                <CircularProgress />
              ) : (
                valueChains &&
                valueChains.map((valueChain) => (
                  <ValueChains
                    key={valueChain.id}
                    category={category}
                    body={body}
                    setBody={setBody}
                    valueChain={valueChain}
                  />
                ))
              )}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const AdvisoriesForm = ({ body, setBody }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [valueChainCategories, setValueChainCategories] = useState([]);

  const getCategoriesList = async () => {
    setIsLoading(true);
    var categoriesRes = await getValueChainCategories();
    if (categoriesRes.status == 200) {
      setValueChainCategories(categoriesRes.data.results);
      categoriesRes.data.results.map((category) =>
        setBody((prevState) => {
          const newState = {
            ...prevState,
            [category["id"]]: {},
          };
          return newState;
        })
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCategoriesList();
  }, []);

  return (
    <Box sx={{ mt: "10px" }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        valueChainCategories &&
        valueChainCategories.map((category) => (
          <ValueChainCategories
            key={category.id}
            category={category}
            body={body}
            setBody={setBody}
            valueChainCategories={valueChainCategories}
          />
        ))
      )}
    </Box>
  );
};

export default AdvisoriesForm;
