import "./App.css";
import React from "react";
import { Card } from "@mui/material";
import FarmerRegistrationForm from "./components/FarmerRegistrationForm.jsx";
import RecordReachForm from "./components/RecordReachForm.jsx";
import RegisterDaForm from "./components/RegisterDaForm.jsx";
import FeedbackForm from "./components/FeedbackForm.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home.jsx";
import { ToastContainer } from "react-toastify";
import RecordAdoptionForm from "./components/RecordAdoptionForm.jsx";
import ReportUserRegistrationForm from "./components/ReportUserRegistrationForm.jsx";
import AddNotification from "./components/notification/add-notification/index.jsx";
import NotificationsList from "./components/notification/notification-list/index.jsx";
import UpdateNotification from "./components/notification/update-notification/index.jsx";
import DevelopmentGroupForm from './components/DevelopmentGroupForm.jsx'

function App() {
  return (
    <div
      style={{
        width: '95vw',
        maxWidth: '600px',
      }}>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Card sx={{ p: 2, m: 1, backgroundColor: '#f5ffff' }}>
        <Router>
          <Routes>
            <Route
              path='/feedback'
              element={<FeedbackForm />}
            />
            <Route
              path='/farmer_registration'
              element={<FarmerRegistrationForm />}
            />
            <Route
              path='/da_registration'
              element={<RegisterDaForm />}
            />
            <Route
              path='/da_update_profile'
              element={<RegisterDaForm isUpdate={true} />}
            />
            <Route
              path='/record_reach'
              element={<RecordReachForm />}
            />
            <Route
              path='/record_adoption'
              element={<RecordAdoptionForm />}
            />
            <Route
              path='/notification/create'
              element={<AddNotification />}
            />
            <Route
              path='/notification/update/:id'
              element={<UpdateNotification />}
            />
            <Route
              path='/notification/list'
              element={<NotificationsList />}
            />
            <Route
              path='/'
              element={<Home />}
            />
            <Route
              path='/register_report_user'
              element={<ReportUserRegistrationForm />}
            />
            <Route
              path='/add_development_group'
              element={<DevelopmentGroupForm />}
            />
            <Route
              path='/'
              element={<Home />}
            />
          </Routes>
        </Router>
      </Card>
    </div>
  )
}

export default App;
