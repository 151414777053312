import React from "react";
import NotificationForm from "../components/NotificationForm";
import { useParams } from "react-router-dom";
import { UPDATE } from "../../../services/constants";

const UpdateNotification = () => {
  const { id, bot_user_id } = useParams();

  return (
    <div>
      <h2>Update Notification Form</h2>
      <NotificationForm mode={UPDATE} id={id} />
    </div>
  );
};

export default UpdateNotification;
