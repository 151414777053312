import React, { useRef } from "react";
import axios from "axios";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  addDevelopmentGroup,
  fetchDevelopmentGroups,
  registerFarmer,
} from "../services/api_services";
import { useLocation } from "react-router-dom";
import { isEmpty } from "../utils/isEmpty";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { parsePhoneNumber } from "../utils/parsePhoneNumber";

const FarmerRegistrationForm = () => {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    add_dev_group: yup.boolean(),
    farmer_name: yup.string().required(t("required")),
    father_name: yup.string().required(t("required")),
    grand_father_name: yup.string().required(t("required")),
    phone_number: yup
      .string()
      .matches(/^(09|07)\d{8}$/, t("phone_number_validation")),
    gender: yup.string().required(t("required")),

    development_group: yup.string().when("add_dev_group", {
      is: (value) => value === false,
      then: (schema) => schema.required(t("required")),
      otherwise: (schema) => schema,
    }),
    development_group_name: yup.string().when("add_dev_group", {
      is: (value) => value === true,
      then: (schema) => schema.required(t("required")),
      otherwise: (schema) => schema,
    }),
    development_group_type: yup.string().when("add_dev_group", {
      is: (value) => value === true,
      then: (schema) => schema.required(t("required")),
      otherwise: (schema) => schema,
    }),
  });

  const genderOptions = [
    {
      value: "male",
      label: t("male"),
    },
    {
      value: "female",
      label: t("Female"),
    },
  ];

  const developmentGroupTypeOptions = [
    {
      value: "men_only",
      label: t("men_only"),
    },
    {
      value: "women_only",
      label: t("women_only"),
    },
    {
      value: "mixed",
      label: t("mixed"),
    },
  ];
  const tele = window.Telegram.WebApp;
  let query = new URLSearchParams(useLocation().search);
  let da_id = query.get("da_id");
  let kebele_id = query.get("kebele_id");
  const [isLoading, setIsLoading] = useState(true);
  const [isDevGroupLoading, setIsDevGroupLoading] = useState(true);
  const [error, setError] = useState();
  const [addDevGroupIsLoading, setAddDevGroupIsLoading] = useState(false);
  const [developmentGroupOptions, setDevelopmentGroupOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    tele.expand();
    tele.MainButton.text = t('register');
    tele.MainButton.show();
    tele.MainButton.onClick(formik.handleSubmit);
    fetchDevelopmentGroups({
      setDevelopmentGroupOptions: setDevelopmentGroupOptions,
      setIsLoading: setIsDevGroupLoading,
      setError: setError,
      kebele_id: kebele_id ?? '',
    });
    setIsLoading(false);
    tele.ready();
  }, [tele, kebele_id]);

  useEffect(() => {
    if (!isEmpty(formErrors)) {
      for (const [key, value] of Object.entries(formErrors)) {
        if (Array.isArray(value)) {
          const errorString = value.join(', ');
          formik.setFieldError(key, errorString);
        } else {
          formik.setFieldError(key, value);
        }
      }
    }
  }, [formErrors]);

  const formik = useFormik({
    initialValues: {
      farmer_name: '',
      father_name: '',
      grand_father_name: '',
      phone_number: '',
      gender: '',
      development_group: '',
      development_group_name: '',
      development_group_type: '',
      add_dev_group: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        var body = {
          phone_number: parsePhoneNumber(values.phone_number),
          farmer_name: values.farmer_name,
          father_name: values.father_name,
          grand_father_name: values.grand_father_name,
          gender: values.gender,
          created_by: da_id,
          kebele: kebele_id,
        };

        if (values.add_dev_group) {
          body.development_group = {
            type: values.development_group_type,
            name: values.development_group_name,
          };
        } else {
          for (var dv_group in developmentGroupOptions) {
            if (
              developmentGroupOptions[dv_group]['value'] ==
              values.development_group
            ) {
              if (!isEmpty(developmentGroupOptions[dv_group]['created_by'])) {
                body.outbound_development_group = values.development_group;
              } else {
                body.development_group = values.development_group;
              }
            }
          }
        }
        setIsLoading(true);
        const response = await registerFarmer({
          body: body,
          setIsLoading: setIsLoading,
        });

        if (!isEmpty(response.status) && response.status == 201) {
          await tele.sendData(
            JSON.stringify({ data: response.data, status_code: 201 })
          );
          setIsLoading(false);

          return;
        } else {
          if (response?.status == 400) {
            setFormErrors(response?.data);
          }

          if (response?.response?.status == 400) {
            setFormErrors(response?.response?.data);
          }

          toast.error(t('error_registering_farmer'), {
            position: 'top-center',
          });
          setIsLoading(false);

          return;
        }
      } catch (error) {
        setIsLoading(false);
        return error;
      }
    },
  });
  const delay = (duration) =>
    new Promise((resolve) => setTimeout(resolve, duration));
  const handleCreateDevGroup = async () => {
    try {
      var isValid = true;
      if (formik.errors.development_group_type != undefined) {
        formik.setFieldTouched("development_group_type");
        formik.setFieldError("development_group_type", "Required");
        isValid = false;
      }
      if (formik.errors.development_group_name != undefined) {
        formik.setFieldTouched("development_group_name");
        formik.setFieldError("development_group_name", "Required");
        isValid = false;
      }
      if (!isValid) return;
      setAddDevGroupIsLoading(true);

      const response = await addDevelopmentGroup({
        body: {
          kebele: kebele_id,
          created_by: da_id,
          group_name: formik.values.development_group_name,
          group_type: formik.values.development_group_type,
        },
        setAddDevGroupIsLoading: setAddDevGroupIsLoading,
      });
      if (response.status == 201) {
        fetchDevelopmentGroups({
          setDevelopmentGroupOptions: setDevelopmentGroupOptions,
          setIsLoading: setAddDevGroupIsLoading,
          setError: setError,
          kebele_id: kebele_id ?? "",
        });

        handleToggleAddDevGroupClicked();

        formik.setFieldTouched("development_group_name", false);
        formik.setFieldTouched("development_group_type", false);
        formik.setFieldError("development_group_name", undefined);
        formik.setFieldError("development_group_type", undefined);
        formik.setFieldValue("development_group_name", "");
        formik.setFieldValue("development_group_type", "");

        setAddDevGroupIsLoading(false);
      } else {
        setAddDevGroupIsLoading(false);
      }
    } catch (e) {}
  };

  const [addDevGroup, setAddDevGroup] = useState(false);

  const handleToggleAddDevGroupClicked = () => {
    formik.setFieldValue("add_dev_group", !addDevGroup);
    setAddDevGroup(!addDevGroup);
  };

  if (isLoading || isDevGroupLoading) return <CircularProgress />;

  return (
    <div>
      <Typography
        variant='h5'
        py={2}>
        {t('register_farmer')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          variant='outlined'
          fullWidth
          margin='dense'
          id='farmer_name'
          name='farmer_name'
          label={t('farmer_name')}
          value={formik.values.farmer_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.farmer_name && Boolean(formik.errors.farmer_name)
          }
          helperText={formik.touched.farmer_name && formik.errors.farmer_name}
        />

        <TextField
          variant='outlined'
          fullWidth
          margin='dense'
          id='father_name'
          name='father_name'
          label={t('father_name')}
          value={formik.values.father_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.father_name && Boolean(formik.errors.father_name)
          }
          helperText={formik.touched.father_name && formik.errors.father_name}
        />

        <TextField
          variant='outlined'
          fullWidth
          margin='dense'
          id='grand_father_name'
          name='grand_father_name'
          label={t('grand_father_name')}
          value={formik.values.grand_father_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.grand_father_name &&
            Boolean(formik.errors.grand_father_name)
          }
          helperText={
            formik.touched.grand_father_name && formik.errors.grand_father_name
          }
        />

        <TextField
          variant='outlined'
          fullWidth
          margin='dense'
          id='phone_number'
          name='phone_number'
          label={t('phone_number')}
          value={formik.values.phone_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.phone_number && Boolean(formik.errors.phone_number)
          }
          helperText={formik.touched.phone_number && formik.errors.phone_number}
        />
        <TextField
          variant='outlined'
          fullWidth
          margin='dense'
          id='gender'
          name='gender'
          label={t('gender')}
          value={formik.values.gender}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          helperText={formik.touched.gender && formik.errors.gender}
          select>
          {genderOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant='outlined'
          fullWidth
          margin='dense'
          id='development_group'
          name='development_group'
          label={t('development_group')}
          disabled={addDevGroup}
          value={formik.values.development_group}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.development_group &&
            Boolean(formik.errors.development_group)
          }
          helperText={
            formik.touched.development_group && formik.errors.development_group
          }
          select>
          {developmentGroupOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {/* {addDevGroup ? (
          <>
            <TextField
              variant="outlined"
              fullWidth
              margin="dense"
              id="development_group_type"
              name="development_group_type"
              label={t("development_group_type")}
              value={formik.values.development_group_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.development_group_type &&
                Boolean(formik.errors.development_group_type)
              }
              helperText={
                formik.touched.development_group_type &&
                formik.errors.development_group_type
              }
              select>
              {developmentGroupTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                id="development_group_name"
                name="development_group_name"
                label={t("development_group_name")}
                value={formik.values.development_group_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.development_group_name &&
                  Boolean(formik.errors.development_group_name)
                }
                helperText={
                  formik.touched.development_group_name &&
                  formik.errors.development_group_name
                }
              />
              {addDevGroupIsLoading ? (
                <Box
                  style={{
                    width: "120px",
                    height: "42px",
                    marginLeft: "10px",
                    fontSize: "14px",
                    // padding: "10px",
                  }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  onClick={handleCreateDevGroup}
                  color="info"
                  variant="outlined"
                  style={{
                    width: "120px",
                    height: "42px",
                    marginLeft: "10px",
                    fontSize: "14px",
                    // padding: "10px",
                  }}
                  type="button">
                  {t("add")}
                </Button>
              )}
            </div>
          </>
        ) : (
          <></>
        )} */}
        {/* <Button
          margin="dense"
          color="primary"
          variant="contained"
          fullWidth
          style={{ marginTop: "10x" }}
          type="submit">
          Submit
        </Button> */}
      </form>
    </div>
  )
};

export default FarmerRegistrationForm;
